import { VdpService } from './vdp.service';
const NON_PROD_SCRIPT_ID = 'nonprod-mmd-script';
/**
 * Use this service to interact with the Widget
 * Common tasks - load non-prod widget
 * @export
 * @class WidgetService
 */
export class WidgetService {
    constructor(private vdp: VdpService) {}
    public shouldLoadNonProdWidget = () => {
        let shoudLoad = false;

        if (this.isNonProdEnvironment() && !this.isNonProdWidgetAlreadyThere()) {
            shoudLoad = true;
        }

        return shoudLoad;
    };
    public loadNonProdWidget = (sponsor?: string) => {
        const environment: WidgetEnvironment = this.vdp.getQueryParam('widgetEnv');
        if (environment && environment == 'local') {
            this.vdp.addScript('https://shop.makemydeal.dev/widget.js', NON_PROD_SCRIPT_ID);
        } else if (environment) {
            const domain = this.getDomain(sponsor);
            this.vdp.addScript(`https://${environment}.${domain}/widget.js`, NON_PROD_SCRIPT_ID);
        }
    };
    private isNonProdEnvironment = () => {
        const environment: WidgetEnvironment = this.vdp.getQueryParam('widgetEnv');
        const supportedNonProdEnvironments = {
            local: true,
            dev: true,
            dev2: true,
            qa: true,
            uat: true
        };
        return supportedNonProdEnvironments[environment];
    };
    // Checks if widget is already there
    private isNonProdWidgetAlreadyThere = (): boolean => {
        const currentScript = this.vdp.getCurrentScript();
        return currentScript.id == NON_PROD_SCRIPT_ID;
    };
    private getDomain = (sponsor?: string) => {
        if (sponsor && (sponsor.toLowerCase() === 'kbb' || sponsor.toLowerCase() === 'atc')) {
            return 'accelerate.dealer.com';
        }
        return 'shop.dealer.com';
    };
}
// Types
type WidgetEnvironment = 'local' | 'dev' | 'dev2' | 'qa' | 'uat' | null;
