// resource: https://coxautoinc-my.sharepoint.com/:x:/r/personal/rishona_phillips_coxautoinc_com/_layouts/15/guestaccess.aspx?rtime=Xec5jlM810g&CID=acfed142-359a-2471-4d77-56eccbd499d8&share=ET1iPIfHYgZAgGKJhw0O41sBci4PrOekQelxXF4XNVL6Wg
const optimizelyEdgeOptInDdcList = {
    46: 1,
    111: 1,
    287: 1,
    354: 1,
    367: 1,
    448: 1,
    468: 1,
    469: 1,
    497: 1,
    573: 1,
    723: 1,
    913: 1,
    1248: 1,
    1647: 1,
    1753: 1,
    1773: 1,
    1815: 1,
    1832: 1,
    1846: 1,
    1849: 1,
    1863: 1,
    2066: 1,
    2069: 1,
    2073: 1,
    2083: 1,
    2176: 1,
    2185: 1,
    2196: 1,
    2198: 1,
    2283: 1,
    2310: 1,
    2318: 1,
    2359: 1,
    2375: 1,
    2386: 1,
    2413: 1,
    2446: 1,
    2447: 1,
    2464: 1,
    2468: 1,
    2496: 1,
    2516: 1,
    2519: 1,
    2522: 1,
    2524: 1,
    2525: 1,
    2528: 1,
    2547: 1,
    2548: 1,
    2604: 1,
    2609: 1,
    2641: 1,
    2652: 1,
    2655: 1,
    2660: 1,
    2666: 1,
    2668: 1,
    2672: 1,
    2712: 1,
    2728: 1,
    2745: 1,
    2789: 1,
    2825: 1,
    2884: 1,
    2912: 1,
    2913: 1,
    2962: 1,
    3010: 1,
    3013: 1,
    3115: 1,
    3311: 1,
    3339: 1,
    3428: 1,
    3492: 1,
    3777: 1,
    3794: 1,
    4052: 1,
    4091: 1,
    4395: 1,
    4429: 1,
    4452: 1,
    4597: 1,
    4658: 1,
    4711: 1,
    4715: 1,
    4743: 1,
    4745: 1,
    4811: 1,
    4862: 1,
    4883: 1,
    5115: 1,
    5162: 1,
    5179: 1,
    5481: 1,
    5549: 1,
    5567: 1,
    5617: 1,
    5623: 1,
    5714: 1,
    5938: 1,
    6003: 1,
    6050: 1,
    6137: 1,
    6297: 1,
    6381: 1,
    6596: 1,
    6612: 1,
    6671: 1,
    6707: 1,
    6725: 1,
    6727: 1,
    6991: 1,
    7264: 1,
    7331: 1,
    7659: 1,
    7782: 1,
    7945: 1,
    8178: 1,
    8378: 1,
    8586: 1,
    8699: 1,
    8701: 1,
    8810: 1,
    8815: 1,
    8943: 1,
    8955: 1,
    9015: 1,
    9032: 1,
    9107: 1,
    9121: 1,
    9162: 1,
    9163: 1,
    9167: 1,
    9183: 1,
    9186: 1,
    9191: 1,
    9200: 1,
    9320: 1,
    9344: 1,
    9356: 1,
    9383: 1,
    9427: 1,
    9450: 1,
    9482: 1,
    9510: 1,
    9567: 1,
    9570: 1,
    9572: 1,
    9625: 1,
    9747: 1,
    9748: 1,
    9749: 1,
    9775: 1,
    9840: 1,
    9854: 1
};

export { optimizelyEdgeOptInDdcList };
