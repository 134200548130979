'use strict';

var log = function(msg) {
    global && global.console && global.console.log && global.console.log(msg);
}; // safe function for browser logging

var _ = require('underscore');
var AppFrame = require('./widget_modules/appframe');
var Config = require('./config');
var GetDataAttributes = require('./getDataAttributes');
var HttpIePlugin = require('./superagent-legacyIESupport'); // IE9 cors plugin
var libUtils = require('./utils');
var loadScript = require('./widget_modules/loadscript');
var MessageBus = require('./widget_modules/messagebus');
var qs = require('qs');
var r2d2 = require('@makemydeal/r2d2').default;
var request = require('superagent');
var scrappy = require('../shims/Scrappy');
var selectn = require('selectn');
var validator = require('./legacy.validator');
var WidgetApi = require('./WidgetApi');


// Declare internals
var internals = {};

// Define hooks
var hooks = {};

module.exports = function(root) {
    var mmd = root.mmd || {};

    // Some dealers have multiple widget.js requests on the site.
    // This logic should ensure only one instance of it loads.
    var loadOnce = mmd.loadOnce;
    if (!loadOnce) {
        mmd.loadOnce = true;
    } else {
        return;
    }

    var VERSION = '4.0.0';

    // mmd version already defined, so script has run and should not run again
    if (mmd.VERSION === VERSION) {
        return;
    }

    // Current version.
    mmd.VERSION = VERSION;

    var filters = {
        // Valid data to be sent to the server when creating vdp app
        dataOptions: [
            'chromeStyleId',
            'chromeId',
            'dealerId',
            'refId',
            'storeId',
            'sponsor',
            'listedPrice',
            'retailPrice',
            'dealerSavings',
            'incentiveSavings',
            'img',
            'certifiedStatus',
            'vehicleStatus',
            'mileage',
            'vin',
            'year',
            'make',
            'model',
            'trim',
            'stock',
            'isMobile',
            'isShimmed',
            'vehicleUrl',
            'widgetType', // Used by atc
            'googleCampaign',
            'testMode'
        ],

        // Valid settings that effect widget which can be passed in by dealer.
        settingsOptions: [
            'target',
            'appendTo',
            'prependTo',
            'insertType',
            'onLoadedFunction',
            'onHidingFunction',
            'isShimmed',
            'disableShim',
            'version'
        ]
    };

    // List of valid external analytic events
    var externalAnalyticsEvents = [
        'dealDisplay',
        'dealPencil',
        'dealStart',
        'dealSent'
    ];

    // Check to see if the site has included hooks (in particular analytics hooks)
    // If so, then loop over allowed hooks and add to hooks objects
    internals.getAnalyticsHooks = function() {
        if (mmd.hooks && mmd.hooks.analyticsHooks) {
            externalAnalyticsEvents.forEach(function(evnt) {
                if (mmd.hooks.analyticsHooks[evnt]) {
                    hooks[evnt] = mmd.hooks.analyticsHooks[evnt];
                }
            });
        }
    };

    // MWO-475 - Function to check if widgetJS is loaded in a hidden div
    internals.isWidgetHidden = function(el, lim) {

        lim = lim || 0;

        el = el || document.querySelector('#mmd-widget');

        function hidden(el) {
            return window.getComputedStyle(el).display === 'none' || window.getComputedStyle(el).visibility === 'hidden';
        }

        //control
        if (lim === 5) {
            return;
        }

        if (el !== null && el.nodeName === 'BODY') {
            return;
        }

        //get parent
        el = el.parentNode || null;

        //search up dom tree for hidden parent
        if (el) {
            if (hidden(el)) {
                log({
                    msg: 'MMD widget is in hidden parent/grandparent container.',
                    parent: el,
                    position: lim + ' elements up.'
                });
                return;
            } else {
                this.isWidgetHidden(el, lim + 1);
            }
        }
    };

    // Quick references
    var $script = document.getElementById('mmd-widget-script') || document.getElementById('makemydeal') || null;
    var scriptConfig = ($script) ? (GetDataAttributes($script)) : {};
    var scriptConfig = _.mapObject(scriptConfig, function(value, index, list) {
        return _.isString(value) ? value.toLowerCase().trim() : value;
    });

    // HACK - DDC passed chromeId instead of chromeStyleId; mapping to chromeStyleId if it exists
    if (scriptConfig && scriptConfig.chromeId) {
        scriptConfig.chromeStyleId = scriptConfig.chromeId;
        delete scriptConfig.chromeId;
    }

    var dealerOptions = {};
    var initDlrOptsFromRoot = function() {
        return !!(mmd && mmd.version && mmd.sponsor && (mmd.refId || mmd.dealerId));
    };

    var dealerOptionsConfig = initDlrOptsFromRoot() ? mmd : scriptConfig;
    dealerOptions = _.extend({}, _.pick(dealerOptionsConfig, filters.dataOptions, filters.settingsOptions));

    /***
     * Detect when widget is not running on valid VDP for certain sponsors.
     **/

    var isInvalidVDP = (function(sponsor, dealerId) {

        var sponsors = {
            'autorevo': {
                validInventory: /\/\d{4}\-/gi
            },
            'clickmotive': {
                validInventory: /\/(for-sale)\/(new|used|used-certified)\/[a-zA-Z]*\/(.*)\/(.*)\/\d*/gi
            },
            'cobalt': {
                validInventory: /\/(vehicledetails)\//gi
            },
            'ddc': {
                validInventory: /\/(wholesale-new|wholesale-used|commercial-new|commercial-used|exotic-used|exotic-new|bargain|used|new|certified|pre-auction)\/|\bvehicle-details.htm\b/gi
            },
            'dealerlab': {
                validInventory: /\/(auto)\//gi
            },
            'dealereprocess': {
                validInventory: /\/(auto|vehicledetails)\/(used|new|preowned)\-|\/\d{4}\-|\-\d{4}\-/gi
            },
            'dealerseo': {
                validInventory: /\/(cars)\/(used|new|preowned)\//gi
            },
            'drivingforce': {
                validInventory: /\/(details)\//gi
            }
        };

        var dealerIds = {
            '67': {
                validInventory: /\/(for-sale)\/(new|used)\/[a-zA-Z]*\//gi
            },
            '945': {
                validInventory: /\/((19|20)\d{2}$)\-([a-zA-Z-]*)\//gi
            },
            '1030': {
                validInventory: /\/cars\/[a-zA-Z]*\//g
            }
        };

        var vdpURL = sponsors[sponsor] && sponsors[sponsor].validInventory ||
            dealerIds[dealerId] && dealerIds[dealerId].validInventory;

        //override functionality to test in w2.makemydealpreview.com
        var validTestHosts = ['w2.makemydealpreview.com', 'localhost', 'widgettest.makemydealpreview.com', 'widgettest.makemydeal.com'];
        if (root.location && validTestHosts.indexOf(root.location.hostname) > -1) {
            return false;
        } else if (root.location && root.location.origin && root.location.origin.indexOf('translate.google.com') > -1) {
            return true;
        } else {
            return root.document.URL.search(vdpURL) === -1 ? true : false;
        }

        return false;
    })(dealerOptions.sponsor, dealerOptions.dealerId);

    if (isInvalidVDP) {
        return;
    }

    // Check to see if using iOS.
    var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

    var messageBus = new MessageBus();

    //frame objects
    var vdpApp;
    var masterModal;

    // Service to allow host page to subscribe to data change events,
    // update data
    var widgetApi;

    var fieldUpdated = function(evnt) {
        widgetApi._emitFieldChange(evnt.payload.name, evnt.payload.value);
    };

    //get analytics hooks
    internals.getAnalyticsHooks();

    var onTriggerExternalAnalyticsEvent = function(evnt) {
        if (hooks[evnt.payload.value]) {
            hooks[evnt.payload.value]();
        }
    };

    var legacyVdpWidgetUrl = Config.services.api.baseWidgetUrl + '/applications/app';
    var legacyModalAppUrl = Config.services.api.baseWidgetUrl + '/applications/modal';
    var legacyVdpShowStyle = {
        display: 'block'
    };
    var legacyModalShowStyle = {
        display: 'block'
    };

    internals.initFrames = function(vdpAppUrl, modalAppUrl, vdpAppShowStyle, modalAppShowStyle) {
        // Create VDP App Frame.
        var isVdpFrameShown = false;
        vdpApp = new AppFrame({
            name: 'vdpApp',
            src: vdpAppUrl,
            frameId: 'frame2',
            target: dealerOptions.target || '#mmd-widget',


            // Set height to 0 initially, a message will tell it how tall to be
            style: {
                display: 'none',
                height: '0',
                width: '100%',
                border: 0,
                clear: 'both',
                padding: 0,
                margin: 0,
                overflow: 'hidden'
            },

            router: messageBus,

            routes: {
                'show': 'onShow',
                'resize': 'onResize',
                'fieldUpdated': 'fieldUpdated',
                'externalAnalytics': 'onTriggerExternalAnalyticsEvent'
            },

            onReconnect: function() {
                // Let's not trigger start if the startConfigCache is empty
                if (this.startConfigCache) {
                    this.trigger('start', this.startConfigCache);
                }
            },

            onShow: function() {
                this.setStyle(vdpAppShowStyle);

                var opLogContext = {
                    sponsor: dealerOptions.sponsor,
                    widget_type: dealerOptions.widgetType,
                    is_mobile: (dealerOptions.isMobile) ? true : false
                };

                // set the dealerId that is returned from the vdp api call
                // dealerId may not always be in the loadWidget call for partners that send in a refId
                if (this.startConfigCache && this.startConfigCache.dealer && this.startConfigCache.dealer.dealerId) {
                    opLogContext.dealer_id = this.startConfigCache.dealer.dealerId;
                }
                if (dealerOptions.onLoadedFunction) {
                    dealerOptions.onLoadedFunction();
                }

                //check if widget is in hidden parent
                internals.isWidgetHidden(document.querySelector(this.target));
            },

            onResize: function(evnt) {
                var height = evnt.payload.height || '';
                if (!height) { return; }
                this.setStyle({
                    height: height
                });
            },

            onTriggerExternalAnalyticsEvent: onTriggerExternalAnalyticsEvent,
            fieldUpdated: fieldUpdated
        });

        // Initialize API
        // TODO: Fix circular dependency
        widgetApi = new WidgetApi(vdpApp);

        // Create VDP Support Frame.
        masterModal = new AppFrame({
            name: 'masterModal',

            src: modalAppUrl,
            frameId: 'frame4',
            target: 'body',

            wrapper: {
                id: 'mmd-modal-wrapper',

                style: {
                    display: 'none',
                    position: 'fixed',
                    right: 0,
                    bottom: 0,
                    left: 0,
                    top: 0,
                    border: 0,
                    '-webkit-overflow-scrolling': 'touch',
                    overflow: iOS ? 'scroll' : undefined,
                    'z-index': 999999999
                }
            },

            style: {
                width: '100%',
                height: '100%',
                border: 0
            },
            router: messageBus,

            routes: {
                'open': 'onOpen',
                'close': 'onClose',
                'resize': 'onResize',
                'fieldUpdated': 'fieldUpdated',
                'externalAnalytics': 'onTriggerExternalAnalyticsEvent'
            },

            onOpen: function() {
                // Save the overflow of the body before dialog was ever opened,
                // so it can be restored on clos e
                if (!this.defaultParentBodyOverflow && document.body) {
                    this.defaultParentBodyOverflow = document.body.style.overflow;
                }
                document.body.style.overflow = 'hidden';
                this.setStyle(modalAppShowStyle, document.getElementById(this.wrapper.id));
            },

            onClose: function() {
                document.body.style.overflow = this.defaultParentBodyOverflow || '';
                this.setStyle({
                    display: 'none'
                }, document.getElementById(this.wrapper.id));
            },

            onResize: function() {
                // no op, but needed since modal shares components that will request resize
            },

            onTriggerExternalAnalyticsEvent: onTriggerExternalAnalyticsEvent,

            fieldUpdated: fieldUpdated
        });

    };

    //initialize frames for 'legacy' widget-app
    internals.initFrames(legacyVdpWidgetUrl, legacyModalAppUrl, legacyVdpShowStyle, legacyModalShowStyle);

    internals.getStorageKey = function(vehicle) {
        var storageKey = new Date().getTime() + ':';

        if (vehicle && vehicle.vin) {
            storageKey += vehicle.vin;
        } else {
            storageKey += new Date().getTime();
        }

        return storageKey;
    };

    internals.getAppConfigs = function(type, data) {
        var vdpData = _.omit(data, 'testMode');
        vdpData.isShimmed = true;
        var payload = JSON.stringify(vdpData);

        var headers = {
            'Content-Type': 'application/json'
        };

        var vdpPost = function(query) {
            request
                .post(Config.services.api.baseApiUrl + '/widgets/vdp')
                .query(query)
                .send(payload)
                .set(headers)
                .use(HttpIePlugin)
                .end(function(error, response) {
                    if (error && (error.status === 403 || error.status === 400)) {
                        log({ error: response.body.message });

                        if (dealerOptions.onHidingFunction) {
                            dealerOptions.onHidingFunction();
                        }

                        return;
                    } else if (error) {
                        var errorMessage = error.response.body.message || error.message || '';

                        log(error);

                        if (dealerOptions.onHidingFunction) {
                            dealerOptions.onHidingFunction();
                        }

                        return;
                    }
                    if (response.body.shim && !dealerOptions.disableShim) {
                        loadScript(response.body.shim, function() {
                            var Isle = mmd.island || mmd.vehicle;
                            // TODO: Remove when island stops requiring URL
                            dealerOptions.url = document.URL;
                            dealerOptions.widgetType = dealerOptions.widgetType || 'midsize';

                            var isle = new Isle(dealerOptions);
                            dealerOptions = _.extend(dealerOptions, isle.getAll());

                            // Tell server shim has run
                            dealerOptions.isShimmed = true;

                            mmd.loadWidget(dealerOptions);
                        });
                        return;
                    } else if (response.body.shim && dealerOptions.disableShim) {
                        // Tell server shim has run
                        dealerOptions.isShimmed = true;
                        mmd.loadWidget(dealerOptions);
                        return;
                    }

                    var startupConfig = response.body;
                    startupConfig.storageKey = internals.getStorageKey(startupConfig.vehicle);

                    masterModal.trigger('start', {
                        storageKey: startupConfig.storageKey,
                        settings: {
                            isMobile: startupConfig.settings.isMobile,
                            toggles: startupConfig.settings.toggles,
                            theme: startupConfig.settings.theme || '',
                            googleCampaign: startupConfig.settings.googleCampaign || ''
                        }
                    });

                    vdpApp.startConfigCache = startupConfig;
                    vdpApp.trigger('start', startupConfig);
                    return;
                });
        };

        var query = {};

        if (data.dealerId) {
            query.dealerId = data.dealerId;
        }

        // Add isOwnerId=true if refId passed instead of dealerId
        if (data.sponsor &&
            data.refId &&
            (data.sponsor.toLowerCase() === 'atc' || data.sponsor.toLowerCase() === 'kbb')) {
            query.ownerId = data.refId;
        }

        if (data.storeId) {
            query.storeId = data.storeId;
        }

        vdpPost(query);
    };

    var loadWidget = function(options) {
        options = options || {};
        var isLegacy = false;

        //skip loading widget log reason for skipping
        if (options.forceHideWidget) {
            log({
                msg: options.forceHideWidget.msg
            });
            return;
        }

        options = validator(options);

        // Add backwards compatibility for target
        if (options.containerId) {
            options.target = '#' + options.containerId;
        }

        if (options.appendToId || options.prependToId) { isLegacy = true; }

        if (options.appendToId || options.appendTo) {
            options.target = (isLegacy) ? '#' + options.appendToId : options.appendTo;
            options.insertType = 'append';
        }

        if (options.prependToId || options.prependTo) {
            options.target = (isLegacy) ? '#' + options.prependToId : options.prependTo;
            options.insertType = 'prepend';
        }

        // Pull out data to be sent to server
        options = _.extend(dealerOptions, _.pick(options, filters.dataOptions, filters.settingsOptions));


        // If Apps were deferred run them now.
        if (vdpApp.isDeferred || vdpApp.isMissing()) {
            // If it was missing, treat as if deferred
            vdpApp.isDeferred = true;

            var frameOpts = {
                onTargetFail: {
                    id: 'mmd-widget',
                    useAnchor: $script
                }
            };
            if (options.target) { frameOpts.target = options.target; }
            if (options.insertType) { frameOpts.insertType = options.insertType; }

            vdpApp.attach(frameOpts);
        }

        if (masterModal.isDeferred || masterModal.isMissing()) {
            // If it was missing, treat as if deferred
            masterModal.isDeferred = true;
            masterModal.attach();
        }

        // Make sure dealer options gets the dealer supplied
        // options.
        dealerOptions = _.extend(dealerOptions, options);

        // Set isMobile to true if screen resolution lower than iPad
        if (!dealerOptions.isMobile) {
            var screenWidth = screen.width || window.innerWidth;
            if (screenWidth < 768) {
                dealerOptions.isMobile = true;
            }
        }


        if (!dealerOptions.googleCampaign) {
            var pageUrlQueryStrings = root.location.search;

            if (pageUrlQueryStrings && pageUrlQueryStrings.indexOf('mmdgclid') > -1) {
                dealerOptions.googleCampaign = libUtils.getQueryParameterByName('mmdgclid', pageUrlQueryStrings);
            }
        }

        // Filter data to be sent to server
        var data = _.extend({}, _.pick(dealerOptions, filters.dataOptions));
        internals.getAppConfigs('custom', data);
    };

    internals.easypath = function(options) {
        options = options || {};


        //HACK ALERT: Need to adjust the sponsor for autosearchtechnologies
        // Sponsor has a limit of 15 characters in the SQL DB.  Change to autosearchtech
        if (dealerOptions && dealerOptions.sponsor === 'autosearchtechnologies') {
            dealerOptions.sponsor = 'autosearchtech';
        }

        //override certain sponsors if dealer sets them wrong
        if (dealerOptions.sponsor) {
            var dealerId = dealerOptions.dealerId || options.dealerId;
            var sponsor = dealerOptions.sponsor;
            sponsor = libUtils.overrideSponsor(sponsor, dealerId);
            dealerOptions.sponsor = sponsor;
        }

        var opts = {
            id: dealerOptions.dealerId || options.dealerId,
            sponsor: dealerOptions.sponsor,
            data: options
        };

        // check for handlers i.e. - onLoadedFunction that dealer site is listening to
        // if available add them to options so that handlers can be assigned to DCA
        if (mmd.handlers) {
            opts.data.handlers = mmd.handlers;
        }

        // Partners or Dealer Sites have ability to define mmd.widgetData dataIsland to pass vehicle data to widget
        // This would be an option so that DCA does not have to be written
        // VIN mobile is currently using this solution
        if (mmd.widgetData) {
            opts.data.dealerDataIsland = mmd.widgetData;
        }

        // run scraping engine
        scrappy(opts, function(err, data) {
            if (err) {
                log({
                    message: err.message,
                    stack: err.stack
                });
                return;
            }

            // MWO-1214 - Required us to not display widget on some listings for a site
            // DealerIds: 327, 532, 731
            // Do not display widget if disableLoad is set to true
            if (data.disableLoad) {
                return;
            }

            loadWidget(data);
        });
    };

    //dealers to force
    var hasScrappyExecuted = false;

    var easyForce = [
        '99', // RBM of Atlanta
        '103', // Tamaroff Motors
        '105', // Sam Swope Honda
        '144', // Mini of Kennessaw
        '155', // Atlanta Luxury Motors
        '156', // ALM Gwinnett
        '157', // ALM Mall of GA
        '158', // ALM Roswell
        '159', // Marietta Luxury Motors
        '172', // Tempe Honda
        '180', // Don Mealy
        '187', // Doug Henry Ford
        '188', // Doug Henry Tarboro
        '189', // Alpha Motorsports
        '212', // Grand Auto Family (Cobalt)
        '216', // Valley Motors GM (Cobalt)
        '267', // Fremont Ford
        '268', // Go Parkway (Cobalt)
        '269', // Parkway Volkswagen (Cobalt)
        '271', // Parkway Hyundai (Cobalt)
        '274', // vwofpuyallup (Cobalt)
        '320', // Lexus of Atlantic City (Cobalt)
        '327', // Planet Hyundai
        '329', // New Cars Inc
        '330', // Boulevard Buick GMC (Cobalt)
        '331', // Boulevard Cadillac
        '344', // Mathews Ford Newark (Cobalt)
        '358', // Hyundai of St. Augustine (Cobalt)
        '359', // Zimbrick Hyundai (Cobalt)
        '360', // Zimbrick Nissan (Cobalt)
        '370', // Mini of Knoxville (Cobalt)
        '385', // Gainesville Kia
        '379', // Lehigh Valley Acura
        '399', // Tamaroff Honda
        '400', // Tamaroff Nissan
        '401', // Atchley Ford
        '405', // Kelly Car Chrysler
        '413', // Huffines Kia Denton
        '425', // Gallery Auto Group (hotfix so remove when sponsor fixes id)
        '431', // New Smyrna CJD
        '437', // Akron Buy Here Pay Here
        '439', // Butler Kia
        '440', // Butler Kia of Fisher
        '441', // Butler Hyundai
        '444', // Dave Sinclair Ford
        '454', // Tindol Ford
        '456', // Montgomeryville Nissan
        '459', // Dave Sinclair Lincoln South
        '460', // Dave Sinclair Lincoln St Peters
        '466', // Bay State Ford
        '473', // New City Nissan
        '485', // James Maverick Motorsports
        '488', // Falcone VW
        '498', // TC Honda Gladstone
        '503', // Oxmoor Toyota
        '515', // Oakes Auto
        '549', // Texas Auto
        '604', // Williams Burg Ford
        '609', // BMW of San Francisco
        '621', // Sundance Chevrolet
        '659', // Bluegrass Honda
        '661', // Capital Ford Charlotte
        '753', // Sundance Chevy
        '784', // White Water Motors
        '867',
        '871',
        '884',
        '886', // Odaniel Auto Mart, Odaniel Mazda
        '887',
        '1107',
        '1227'
    ];

    var easyForceSponsor = ['vin', 'atc'];

    /**
     * Some web providers do not send us a sponsor and thus dealers in the easyForce list below
     * are not running the sponsor shim when they should be.  Ensuring they run the sponsor shim
     * so they will load
     * Do not add dealerId 344 to this list as there are multiple sites
     */
    var dealersBySponsor = {
        dominion: [103, 399, 400, 784],
        cobalt: [144, 212, 216, 234, 268, 269, 271, 330, 331, 358, 359, 360, 488]
    };

    // NOTE: This is where we would put any custom inject requirements
    // needed in the future.
    var injectionWrap = function(options) {
        try {
            //force dealers in easyForce to use Scrappy
            var dealerId = dealerOptions.dealerId || options.dealerId || '';
            var sponsor = dealerOptions.sponsor || options.sponsor || '';
            var isMMD = options.sponsor === 'MMDOT' || options.sponsor === 'MMD-VDP' || false;

            if (!hasScrappyExecuted) {
                if (!isMMD && (easyForce.indexOf(dealerId.toString()) !== -1 || easyForceSponsor.indexOf(sponsor) !== -1) && !options.disableShim) {

                    // Add isShimmed true for all dealers being forced into easypath
                    options.isShimmed = true;

                    // If sponsor has not been provided as a data-sponsor option on the widget.js
                    // script, we need to set the sponsor so that when forced into Scrappy it will
                    // execute the sponsor shim.
                    var isDealerIdAnArray = (dealerId.constructor === Array);
                    if (dealerOptions && !dealerOptions.sponsor && !options.sponsor) {
                        dealerId = (isDealerIdAnArray) ? dealerId[0] : dealerId;

                        sponsor = _.findKey(dealersBySponsor, function(dlrArr) {
                            return _.indexOf(dlrArr, dealerId) !== -1;
                        });

                        //hard-coding sponsor for dealerId 344 based on the domain name
                        //since they have three sites
                        if (dealerId === 344) {
                            var dealerUrl = document.location.hostname;
                            if (dealerUrl.search(/mathewsford/g)) {
                                sponsor = 'forddirect';
                            } else {
                                sponsor = 'cobalt';
                            }
                        }
                    }
                    // Need to set sponsor on dealerOptions object otherwise shims won't work
                    if (sponsor) {
                        dealerOptions.sponsor = sponsor;
                    }

                    hasScrappyExecuted = true;
                    return internals.easypath(options);
                }

                loadWidget(options);
            }
        } catch (e) {
            log({
                message: e.message,
                stack: e.stack
            });
        }


    };

    // If dealer is using version 4.0.0
    // use easy path.
    // TODO: need better solutions here.
    if (dealerOptions.version === VERSION && !hasScrappyExecuted) {
        try {
            hasScrappyExecuted = true;
            internals.easypath();
        } catch (e) {
            log({
                message: e.message,
                stack: e.stack
            });
        }
    }

    // TODO - FIX THIS
    // Use mapreduce on price list and then select
    // Legacy comments: The dealer site may have multiple values that could be used as the retail price.
    // Legacy comments: They can use this helper function to help find a valid option.
    mmd.getRetailPrice = function(price, retailPrices) {
        var i;
        if (typeof retailPrices === 'string') {
            retailPrices = [retailPrices];
        }
        for (i = 0; i < retailPrices.length; i++) {
            if (retailPrices[i] && retailPrices[i] > price) {
                return retailPrices[i];
            }
        }
        // If no valid retail prices were found, default to the price.
        return price;
    };

    // Expose widgetApi public interface
    mmd.widgetApi = {
        addFieldChangeListener: widgetApi.addFieldChangeListener.bind(widgetApi),
        removeFieldChangeListener: widgetApi.removeFieldChangeListener.bind(widgetApi),
        updateField: widgetApi.updateField.bind(widgetApi)
    };

    /**
     * A public function that, when called by the dealer,
     * starts the process of creating a widget.
     * @param  {object}
     * @return {iframe}
     */
    mmd.loadWidget = injectionWrap;

    // Backward compatible
    mmd.createWidget = injectionWrap;

    // Needed for old custom proxies
    mmd.clickmotive = {
        createWidget: injectionWrap
    };

    mmd.cobalt = {
        createWidget: injectionWrap
    };

    mmd.ddc = {
        createWidget: injectionWrap
    };

    return mmd;

};