// Fix to make it work in IE 11
if (!(global as any)._babelPolyfill) {
    require('babel-polyfill');
}

// tslint:disable-next-line:no-var-requires
const r2d2 = require('@makemydeal/r2d2').default;

import * as legacyWidget from '../legacy/legacy.widget.module';
import { verifyData } from './widget_modules/core.accelerate';
import { executeDCA } from './widget_modules/dca.accelerate';
import { startAccelerateDigitalRetailing } from './widget_modules/load.accelerate';
import { Logger } from './utils/logger';
import { IAccelerateWindow } from './models/IAccelerateWindow';
import { VdpService } from './services/vdp.service';
import { WidgetService } from './services/widget.service';
import { ICADigitalRetailObject } from './models/ICADigitalRetailObject';
import { initializeOpenSdp } from './sdp/widget.sdp';
import { convertCadrToSdpInit } from './sdp/convertCadrToSaveOffer';

r2d2.initMaster();

declare const window: IAccelerateWindow;
export const vdpService = new VdpService(window); // use to interact with VDP
export const widgetService = new WidgetService(vdpService); // use to interact with Widget

export const shouldOpenSdpDirectly = (bootstrapData: ICADigitalRetailObject): boolean => {
    const qs = vdpService.getQueryParams();
    const { connectionId, vin = bootstrapData.vehicleData.vin, autoOpen, dealXgId, dealXgVersion } = qs as any;

    // US827866: remove this bit of code [(dealXgId && dealXgVersion && autoOpen !== 'false') ||]
    // from the line below after team Rocket has had Hyundai successfully add autoOpen=true to their url
    return (dealXgId && autoOpen !== 'false') || (connectionId && vin) || autoOpen === 'true'; // just need that it exists here
};

/** Start the application, whether it is Accelerate or Legacy */
export const startDigitalRetailing = (bootStrapData: ICADigitalRetailObject) => {
    const logger = Logger.getLogger();
    logger.createNewVdpVisit(); // to track new VDP visits along session
    logger.updateContext({ context: bootStrapData }); // Need to update context on start for every vdp load

    logger.logEvent({ event: 'widget_started' });

    let formattedData: any = null;
    if (bootStrapData && Object.keys(bootStrapData).length > 0) {
        executeDCA(bootStrapData)
            .then((dcaData: any) => {
                formattedData = dcaData;
                formattedData.shopper = bootStrapData.shopper;
                logger.logEvent({ event: 'verify_started' });
                // This is the call to the verify service (not a function that does synchronous validation)
                return verifyData(formattedData);
            })
            .then((verifyResponse) => {
                logger.logEvent({ event: 'verify_finished' });

                // In the case there is no verifyResponse.result property look at the ok property
                if (verifyResponse.result === false || verifyResponse.ok === false) {
                    const err = Error(`Widget configuration data error: ${JSON.stringify(verifyResponse)}`);
                    logger.error(err, 'verify-no-experience');
                    return; // Do nothing, shouldn't load the application
                }

                formattedData.dealer.id = verifyResponse.dealerId;
                // Pass metadata object to DS and SP
                formattedData.metadata = bootStrapData.metadata || {};
                formattedData.dealer.isRedesignEnabled = verifyResponse.isRedesignEnabled;

                // [US121447] Check the vehicleData to see if ownerId has a value. If yes, it is the listing code;
                // if not, use the accountId as the listing code. For ATC/KBB, this listingCode property
                // is not used in dealstarter to pass the value to DTM, ownerId is used instead.
                let listingCode = bootStrapData.vehicleData ? bootStrapData.vehicleData.ownerId : '';
                if (!listingCode) {
                    listingCode = bootStrapData.accountId;
                }

                const dealerOptions: any = {
                    sponsor: formattedData.websiteProviderId,
                    widgetType: formattedData.widgetType,
                    isMobile: formattedData.isMobile || bootStrapData.isMobile,
                    onLoadedFunction: bootStrapData.hooks ? bootStrapData.hooks.onLoadedFunction : '',
                    target: formattedData?.dealer?.target || undefined,
                    ddcSiteId: bootStrapData?.additionalData?.campaignData?.siteId || undefined,
                    listingCode,
                    isActivEngageEnabled: verifyResponse.isActivEngageEnabled
                };

                if (bootStrapData?.hooks?.onTrack) {
                    dealerOptions.onTrack = bootStrapData.hooks.onTrack;
                } else if (formattedData?.hooks?.onTrack) {
                    dealerOptions.onTrack = formattedData.hooks.onTrack;
                }

                if (verifyResponse.experience === 'dealStarter') {
                    startAccelerateDigitalRetailing(formattedData, dealerOptions, verifyResponse.config).catch((error) => {
                        if (error && error.message === 'DEAL360_ERROR') {
                            const err = Error('Widget configuration data error: missing shopper data');
                            logger.error(err, 'deal360-invalid');
                            return; // Do nothing, shouldn't load the application
                        }
                    });
                } else {
                    logger.logEvent({ event: 'legacy_started' });
                    // Legacy application, load it last to speed up time for most of our user base
                    window.mmd = legacyWidget(window);
                }

                return;
            })
            .catch((error: any) => {
                logger.error(error, 'widget_failed');
                throw error;
            });
    } else {
        logger.logEvent({ event: 'legacy_started_2' });
        // Below line breaks this alert
        // tslint:disable-next-line:max-line-length
        // https://portal.dynatrace.com/index.jsp#apm-edit-tests;history=operational-summary.test-management;restartApplication=true;drillInAction=multiTestEdit;bbList=22052506;ref=test-management
        // No impact to Production Legacy Dealers.
        window.mmd = legacyWidget(window);
    }
};

export const startWidget = () => {
    // Checks if we need to use non-prod widget
    if (widgetService.shouldLoadNonProdWidget()) {
        // Will add the widget specifc to the environment
        // Reroute to dev/qa/uat widgetjs
        widgetService.loadNonProdWidget();
        // Will return from this script to load from newly added widget.
        return;
    }
    const bootStrapData = vdpService.getBootstrapData();

    if (shouldOpenSdpDirectly(bootStrapData)) {
        // should we load DS after we close the new widget?
        const qs = vdpService.getQueryParams();
        const { dsLoad = 'false', dealXgId, dealXgVersion } = qs as any;
        const loadDealStarter = dsLoad === 'true' || dealXgId;
        const init = convertCadrToSdpInit(bootStrapData, qs, loadDealStarter ? startDigitalRetailing : undefined);
        initializeOpenSdp(init);
    } else {
        startDigitalRetailing(bootStrapData);
    }
};
