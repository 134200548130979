var EventEmitter = require('events').EventEmitter;

function WidgetApi(appFrame) {
	this._emitter = new EventEmitter();
	this.appFrame = appFrame;
}

WidgetApi.prototype.addFieldChangeListener = function(fieldName, cb) {
	this._emitter.on('field_' + fieldName, cb);
};

WidgetApi.prototype.removeFieldChangeListener = function(fieldName, cb) {
	this._emitter.removeListener('field_' + fieldName, cb);
};

WidgetApi.prototype._emitFieldChange = function(fieldName, data) {
	this._emitter.emit('field_' + fieldName, data);
};

WidgetApi.prototype.updateField = function(fieldName, value) {
	var payload = { name: fieldName, value: value };
	this.appFrame.trigger('updateField', payload);
};

module.exports = WidgetApi;
