
/*
* Append a <script> tag to the document HEAD.
*/
module.exports = function(address, callback) {
    var count = 0; 

    var scriptTag = {
    	el: document.createElement('script'),
    	success: function(){
    		return callback();
    	},
    	error: function(){
            count = ++count;

            if (count > 1) {
                return;
            }
    		return callback(true);
    	}
    }

    scriptTag.el.setAttribute("type", "text/javascript");
    scriptTag.el.setAttribute("src", address);
    scriptTag.el.async = false;
    scriptTag.el.onerror = scriptTag.error;
    scriptTag.el.onload = scriptTag.success;

    // Try to find the head, otherwise default to the documentElement
    (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(scriptTag.el);
}