'use strict';

var _ = require('underscore');
var Config = require('./../config');
/**
 * Message router used for communication between host
 * and apps.
 *
 * @param {obj} options Message router settings
 */
var MessageBus = function MessageBus() {
    var legacyWidgetProtocol = Config.services.api.baseWidgetProtocol;
    var dealStarterProtocol = 'https://';
    this.whitelist = [
        legacyWidgetProtocol + 'widget.makemydeal.com',
        legacyWidgetProtocol + 'widget-staging.makemydeal.com',
        legacyWidgetProtocol + 'widget-staging2.makemydeal.com',
        legacyWidgetProtocol + 'widget-staging3.makemydeal.com',
        legacyWidgetProtocol + 'widget-qa.makemydealpreview.com',
        legacyWidgetProtocol + 'widget-qa2.makemydealpreview.com',
        legacyWidgetProtocol + 'widget-qa3.makemydealpreview.com',
        legacyWidgetProtocol + 'widget-dev.makemydealpreview.com',
        legacyWidgetProtocol + 'localhost:5000',
        legacyWidgetProtocol + 'w2.makemydealpreview.com:5000',
        // DS 2.0 BFF Urls
        dealStarterProtocol + 'dealstarter.makemydeal.com',
        dealStarterProtocol + 'dealstarter-dark.makemydeal.com',
        dealStarterProtocol + 'dealstarter-blue.makemydeal.com',
        dealStarterProtocol + 'dealstarter-green.makemydeal.com',
        dealStarterProtocol + 'dealstarter-staging.makemydeal.com',
        dealStarterProtocol + 'dealstarter-uat.makemydeal.com',
        dealStarterProtocol + 'dealstarter-qa.makemydeal.com',
        dealStarterProtocol + 'dealstarter-dev.makemydeal.com',
        dealStarterProtocol + 'dealstarter.makemydeal.dev',
        // Accelerate Urls
        dealStarterProtocol + 'accelerate.dealer.com',
        dealStarterProtocol + 'dark.accelerate.dealer.com',
        dealStarterProtocol + 'uat.accelerate.dealer.com',
        dealStarterProtocol + 'qa.accelerate.dealer.com',
        dealStarterProtocol + 'dev.accelerate.dealer.com',
        // Shop Urls
        dealStarterProtocol + 'shop.dealer.com',
        dealStarterProtocol + 'dark.shop.dealer.com',
        dealStarterProtocol + 'uat.shop.dealer.com',
        dealStarterProtocol + 'qa.shop.dealer.com',
        dealStarterProtocol + 'dev.shop.dealer.com',
        dealStarterProtocol + 'dr3-assembler-nonprod-us-east-1.service.web-np.dealer.com',
        // Local
        legacyWidgetProtocol + 'dealstarter.makemydeal.dev:5501',
        legacyWidgetProtocol + 'w2.makemydealpreview.com:5501',
        'http://localhost:5501',
        'https://8081.localport.dealer.com',
        'https://8082.localport.dealer.com',
        'https://8083.localport.dealer.com'
    ];
    
    if (process.env.DEV_BOX) {
        this.whitelist.push(`${legacyWidgetProtocol}widget-dev${process.env.DEV_BOX}.makemydealpreview.com`);
    }

    this.routes = {};
    window.addEventListener('message', _.bind(this.broker, this), false);
};

MessageBus.prototype.checkSource = function(origin) {
    var isValid = this.whitelist.some(function(element) {
        return element === '*' || element === origin;
    });
    return isValid;
};

MessageBus.prototype.canIgnoreMessage = function(event) {
    var message = JSON.parse(event.data);
    return !message.instanceId;
}

MessageBus.prototype.broker = function(event) {
    var isAllowed = this.checkSource(event.origin);

    if (!isAllowed) {
        // QUESTION: some sort of logging?
        return;
    }

    if (this.canIgnoreMessage(event)) {
        // This message is from an external source- don't handle it here.
        return;
    }

    var message = JSON.parse(event.data);
    // Fin namespace
    var namespace = message.instanceId + '_';
    var callback = this.routes[namespace + message.type];

    if (!callback) {
        throw new Error('No callback found for message event ' + message.type);
    }

    callback(message);
    return;
};

MessageBus.prototype.bindRoutes = function(context, routes) {

    // Set route namespace for frame
    var namespace = context.instanceId + '_';

    var addRoute = function(method, route) {
        var methodFunc = context[method];

        if (!methodFunc) {
            throw new Error('There is no event handler ' + method);
        }

        // Bind route handler to the options object used to init frame.
        // TODO: Review.
        this.routes[namespace + route] = _.bind(methodFunc, context);
    };
    _.each(routes, addRoute, this);

    return;
};


module.exports = MessageBus;