import { AppFrame } from './appframe';
import { VdpService } from '../services/vdp.service';
import { MessageBus } from './messagebus';
import * as initAuryc from '../initAuryc';
import { WidgetApi } from '../WidgetApi';
import { Logger } from '../utils/logger';
import * as QueryParams from './queryParams';
import { interval } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';
import { IAccelerateWindow } from '../../lib/models/IAccelerateWindow';

let isVdpFrameRendered = false;

const iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
const isIpadLegacy = /iPad/.test(navigator.platform);
const logger = Logger.getLogger();
const DEFAULT_POLLING_TIMEOUT_MS = 600000;
const DEFAULT_POLLING_INTERVAL_MS = 200;
const DEFAULT_VIEWPORT = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
const IPAD_VIEWPORT = 'width=device-width, initial-scale=0.5, maximum-scale=1.0, user-scalable=0';

const fieldUpdated = (event) => {
    const widgetApi = new WidgetApi(this);
    widgetApi.emitFieldChange(event.payload.name, event.payload.value);
};

const onResizeHandler = (data, target) => {
    const dimensions = data.payload.columns;
    const wrapper = target instanceof HTMLElement ? target : document.getElementById(target.slice(1));

    // Get wrapper width otherwise set to minimum allowed.
    const frameWidth = wrapper ? wrapper.offsetWidth : 200;
    let height = '';

    for (const view in dimensions) {
        if (dimensions.hasOwnProperty(view)) {
            const min = dimensions[view].widthRange.min;
            const max = dimensions[view].widthRange.max;
            if (frameWidth >= min && frameWidth <= max) {
                height = dimensions[view].height + dimensions[view].unit;
            }
        }
    }

    return height;
};

export const isVDPFrameInViewport = (element: Element) => {
    const bounding = element.getBoundingClientRect();
    return (
        bounding.top <= document.documentElement.clientHeight &&
        bounding.top + element.clientHeight > 0 &&
        bounding.left >= 0 &&
        bounding.right >= 0
    );
};

export const getIsVdpFrameShown = () => {
    return isVdpFrameRendered;
};

export const setIsVdpFrameShown = (val) => {
    isVdpFrameRendered = val;
    return isVdpFrameRendered;
};

/**
 * Solves equations of the form a * x = b
 * @param {Object} Config object containing VDPApp URL, ModalApp URL, VDPApp styles, ModalApp styles.
 * @param {Object} Target Object to measure width against to calculate height.
 * @returns {function} Returns the
 */
// tslint:disable-next-line: max-line-length
// TODO: 4/10/2017 - on next iteration, take a close look at all methods and hooks AppFrame offers and determine what really needs in place
// and if there is better way to do things.
export const FramesFactory = (frameConfig: IFrameFactoryConfig) => {
    const messageBus = new MessageBus();

    const vdpApp = createVdpApp(
        {
            src: frameConfig.vdpAppUrl,
            style: frameConfig.vdpAppShowStyle,
            target: frameConfig.dealerOptions.target || '#mmd-widget',
            onLoadedFunction: frameConfig.dealerOptions.onLoadedFunction,
            onTrack: frameConfig.dealerOptions.onTrack,
            onDelayLoaded: frameConfig.onDelayLoaded,
            handleActivEngage: frameConfig.handleActivEngage
        },
        messageBus
    );

    // Create VDP Support Frame.
    const masterModal = createModalApp(
        {
            src: frameConfig.modalAppUrl,
            style: frameConfig.modalAppShowStyle,
            onTrack: frameConfig.dealerOptions.onTrack
        },
        messageBus
    );

    return {
        VdpApp: vdpApp,
        MasterModal: masterModal
    };
};

// https://stackoverflow.com/a/54470889
function getAllCSSVariableNames() {
    const styleSheets = document.styleSheets as any;
    const cssVars = [];
    for (let i = 0; i < styleSheets.length; i++) {
        try {
            for (let j = 0; j < styleSheets[i].cssRules.length; j++) {
                try {
                    for (let k = 0; k < styleSheets[i].cssRules[j].style.length; k++) {
                        const name = styleSheets[i].cssRules[j].style[k];
                        if (name.startsWith('--') && cssVars.indexOf(name) == -1) {
                            cssVars.push(name);
                        }
                    }
                } catch (error) {}
            }
        } catch (error) {}
    }
    return cssVars;
}

function getElementCSSVariables(allCSSVars, element = document.body) {
    const elStyles = window.getComputedStyle(element);
    const cssVars = {};
    for (let i = 0; i < allCSSVars.length; i++) {
        const key = allCSSVars[i];
        const value = elStyles.getPropertyValue(key);
        if (value) {
            cssVars[key] = value;
        }
    }
    return cssVars;
}

// DS
const createVdpApp = (options: IAppConfiguration, messageBus: MessageBus) => {
    const { src, target, style, onLoadedFunction, onTrack, onDelayLoaded, handleActivEngage } = options;

    const hostWindow = (window as unknown) as IAccelerateWindow;
    let vdpService: VdpService;

    // Create VDP App Frame.
    setIsVdpFrameShown(false);

    // TODO: Check with team GearShift after 7/30/2019 if this toggle hasn't been cleaned up by then
    const skeletonEnabled = (QueryParams.getParam('enableToggles') || '').indexOf('enableSkeletonScreen') >= 0;

    return new AppFrame({
        name: 'vdpApp',
        src,
        frameId: 'frame2',
        target: target || '#mmd-widget',
        title: 'DealStarter Widget',
        // Set height to 0 initially, a message will tell it how tall to be
        style: {
            display: skeletonEnabled ? 'block' : 'none',
            height: skeletonEnabled ? '640px' : '0',
            width: '100%',
            border: 0,
            clear: 'both',
            padding: 0,
            margin: 0,
            overflow: 'hidden'
        },

        router: messageBus,

        // routes are event channels and the names are event handlers on recieving an event
        // For example: onShow is subscribing to events on show channel
        routes: {
            show: 'onShow',
            resize: 'onResize',
            fieldUpdated: 'fieldUpdated',
            externalTrack: 'onTrack',
            setHeight: 'onSetHeight',
            startAurycRecording: 'startAuryc',
            setUseAnimatedHeight: 'onEnableAnimatedHeight',
            startLoadingSpShell: 'onStartLoadingSpShell',
            requestCadrObject: 'onRequestCadrObject',
            requestAEId: 'onRequestAEId'
        },

        onStartLoadingSpShell() {
            if (onDelayLoaded) {
                onDelayLoaded();
            }
        },

        onSyncStyles() {
            const cssVars = getAllCSSVariableNames();
            const styles = getElementCSSVariables(cssVars, document.documentElement);
            this.trigger('syncStyles', styles);
        },

        onReconnect() {
            // Let's not trigger start if the startConfigCache is empty
            if (this.startConfigCache) {
                this.trigger('start', this.startConfigCache);
            }

            this.onSyncStyles();
        },

        pollWidgetVisibility() {
            const subscription = interval(DEFAULT_POLLING_INTERVAL_MS)
                .pipe(
                    filter(() => getIsVdpFrameShown()),
                    filter(() => isVDPFrameInViewport(this.$el)),
                    timeout(DEFAULT_POLLING_TIMEOUT_MS)
                )
                .subscribe(
                    (response) => {
                        this.trigger('WidgetInViewAnalytic', {});
                        subscription.unsubscribe();
                    },
                    (err) => {
                        console.error(err);
                    },
                    () => {
                        console.log('Widget view poll completed');
                    }
                );
        },

        onShow() {
            setIsVdpFrameShown(true);
            this.setStyle(style);

            if (onLoadedFunction) {
                onLoadedFunction();
            }

            // Check if widget is in hidden parent
            const hiddenCallback = (el, lim) => {
                logger.error({
                    msg: 'MMD widget is in hidden parent/grandparent container.',
                    parent: el,
                    position: lim + ' elements up.'
                });

                return;
            };

            // checks if the parent is hidden, if hidden calls hidden callback
            this.isParentHidden(
                this.target instanceof HTMLElement ? this.target : document.querySelector(this.target),
                0,
                hiddenCallback
            );
            logger.logEvent({ event: 'dealstarter_finished_initialization' });
        },

        onResize(data) {
            const height = onResizeHandler(data, this.target);

            if (!height) {
                return;
            }
            if (getIsVdpFrameShown()) {
                this.setStyle({
                    height
                });
            } else {
                style.height = height;
            }
        },
        onSetHeight(data) {
            let height = data.payload.height;
            height = height ? height : '0px';

            if (getIsVdpFrameShown()) {
                this.setStyle({
                    height
                });
            } else {
                options.style.height = height;
            }
        },
        onEnableAnimatedHeight() {
            this.setStyle({
                transition: '0.4s height ease-in'
            });
        },
        onRequestCadrObject() {
            if (!vdpService) {
                vdpService = new VdpService(hostWindow);
            }
            this.trigger('receiveCadrObject', vdpService.getBootstrapData());
        },
        async onRequestAEId() {
            if (!vdpService) {
                vdpService = new VdpService(hostWindow);
            }
            if (handleActivEngage) {
                const aeToken = await handleActivEngage();
                this.trigger('receiveAEId', { aeToken });
            }
        },
        onTrack: externalOnTrack(onTrack),
        fieldUpdated,
        startAuryc: initAuryc // once widget recieves message it should run Full Story
    });
};

// SP
const createModalApp = (options: IAppConfiguration, messageBus: MessageBus) => {
    const shouldDelaySpShell = (QueryParams.getParam('enableToggles') || '').indexOf('delayLoadSpShell') >= 0;

    const { src, style, onTrack } = options;

    let viewPortContent;
    let temporaryViewport;
    const viewPortMeta = document.querySelector('meta[name="viewport"]');
    if (viewPortMeta) {
        viewPortContent = viewPortMeta.getAttribute('content');
    }

    return new AppFrame({
        name: 'masterModal',
        src: shouldDelaySpShell ? 'about:blank' : src,
        frameId: 'frame4',
        target: 'body',
        title: 'ShopperPlatform Modal',

        wrapper: {
            id: 'mmd-modal-wrapper',

            style: {
                display: 'none',
                position: 'fixed',
                right: 0,
                bottom: 0,
                left: 0,
                top: 0,
                border: 0,
                '-webkit-overflow-scrolling': 'touch',
                overflow: iOS ? 'scroll' : undefined,
                'z-index': style['z-index'] || 2147483647
            }
        },

        style: {
            width: '100%',
            height: '100%',
            border: 0
        },
        router: messageBus,

        routes: {
            open: 'onOpen',
            close: 'onClose',
            resize: 'onResize',
            fieldUpdated: 'fieldUpdated',
            externalTrack: 'onTrack'
        },

        onOpen() {
            // Save the overflow of the body before dialog was ever opened,
            // so it can be restored on close
            if (!this.defaultParentBodyOverflow && document.body) {
                this.defaultParentBodyOverflow = document.body.style.overflow;
            }
            document.body.style.overflow = 'hidden';
            this.setStyle(style, document.getElementById(this.wrapper.id));

            if (isIpadLegacy) {
                if (viewPortMeta) {
                    viewPortMeta.setAttribute('content', IPAD_VIEWPORT);
                } else {
                    // Since the viewport meta doesnt exist, we'll temporarily create one
                    temporaryViewport = document.createElement('meta');
                    temporaryViewport.name = 'viewport';
                    temporaryViewport.content = IPAD_VIEWPORT;
                    document.getElementsByTagName('head')[0].appendChild(temporaryViewport);
                }
            } else if (viewPortMeta && viewPortContent) {
                viewPortMeta.setAttribute('content', DEFAULT_VIEWPORT);
            }
        },

        onClose() {
            document.body.style.overflow = this.defaultParentBodyOverflow || '';
            this.setStyle(
                {
                    display: 'none'
                },
                document.getElementById(this.wrapper.id)
            );
            if (temporaryViewport) {
                temporaryViewport.setAttribute('content', DEFAULT_VIEWPORT);
                temporaryViewport.remove();
                temporaryViewport = undefined;
            }

            if (viewPortMeta && viewPortContent) {
                viewPortMeta.setAttribute('content', viewPortContent);
            }
        },

        onResize() {
            // no op, but needed since modal shares components that will request resize
        },
        onTrack: externalOnTrack(onTrack),
        fieldUpdated
    });
};
interface IAppConfiguration {
    src: string;
    style: any;
    target?: string;
    onLoadedFunction?: () => void;
    onTrack: (message, action, data) => void;
    onDelayLoaded?: () => void;
    handleActivEngage?: () => void;
}
interface IFrameFactoryConfig {
    vdpAppUrl: string;
    vdpAppShowStyle: any;
    modalAppUrl: string;
    modalAppShowStyle: any;
    onDelayLoaded?: () => void;
    handleActivEngage?: () => void;
    dealerOptions: {
        target: string;
        onLoadedFunction: () => void;
        onTrack: (message, action, data) => void;
    };
}

export const externalOnTrack = (onTrackHook) => {
    return (eventMsg) => {
        let payload;
        if (typeof eventMsg.payload === 'string') {
            payload = JSON.parse(eventMsg.payload);
            if (!payload.eventName) {
                return;
            }
        } else {
            payload = eventMsg.payload;
        }

        if (onTrackHook) {
            onTrackHook(payload.eventName, payload.eventAction, payload.eventData);
        }
    };
};
