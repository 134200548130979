import { EventEmitter } from 'events';
import { AppFrame } from './widget_modules/appframe';

export class WidgetApi {
    private emitter: EventEmitter;
    private appFrame: AppFrame;
    constructor(appFrame) {
        this.emitter = new EventEmitter();
        this.appFrame = appFrame;
    }
    public addFieldChangeListener(fieldName, cb) {
        this.emitter.on('field_' + fieldName, cb);
    }

    public removeFieldChangeListener(fieldName, cb) {
        this.emitter.removeListener('field_' + fieldName, cb);
    }

    public emitFieldChange(fieldName, data) {
        this.emitter.emit('field_' + fieldName, data);
    }

    public updateField(fieldName, value) {
        const payload = { name: fieldName, value };
        this.appFrame.trigger('updateField', payload);
    }
}
