import * as qs from 'qs';
import { FramesFactory } from './framesFactory';
import { scrollTo } from '../utils';
import { Logger } from '../utils/logger';
import { IAccelerateExperience } from '../models/IAccelerateExperience';
import * as Config from '../config';
import * as QueryParams from './queryParams';

import { IAccelerateWindow } from '../models/IAccelerateWindow';
import { optimizelyEdgeOptInDdcList } from '../utils/optimizelyEdgeOptInDdc';
import { IDealerConfig } from '../models/IDealerConfig';
import { getActivEngageId } from '../sdp/utils/getActivEngageId';

declare const window: IAccelerateWindow;

export const getPencilUrl = (sponsor, dealerId, query) => {
    return `${Config.getdsApiUrl(sponsor)}/ds/shells/${dealerId}/sidebar.html?${query}`;
};

export const getMainUrl = (sponsor, dealerId, query) => {
    return `${Config.getdsApiUrl(sponsor)}/ds/shells/${dealerId}/main.html?${query}`;
};

export const getWidgetHeight = (options: { isMobile: boolean; vehicleStatus: string }): string => {
    const { isMobile, vehicleStatus } = options;
    let widgetHeight = '';

    if (isMobile) {
        if (vehicleStatus === 'new') {
            widgetHeight = '725px';
        } else {
            widgetHeight = '600px';
        }
    } else {
        if (vehicleStatus === 'new') {
            widgetHeight = '655px';
        } else {
            widgetHeight = '550px';
        }
    }

    return widgetHeight;
};

/**
 * deal360 - add optional VIN/CRM-provided params identifying a dealer user creating a deal on behalf of a shopper
 * @param iFrameQueryParamObject
 */
const addDealerUserParams = (iFrameQueryParamObject: IAccelerateExperience): void => {
    const dsRole = QueryParams.getParam('dsRole');
    if (dsRole !== 'dealer') return;
    const firstName = QueryParams.getParam('fn');
    const lastName = QueryParams.getParam('ln');
    const email = QueryParams.getParam('email');
    if (!firstName || !lastName || !email) {
        throw new Error('DEAL360_ERROR');
    }
    iFrameQueryParamObject.firstName = firstName;
    iFrameQueryParamObject.lastName = lastName;
    iFrameQueryParamObject.email = email;
    iFrameQueryParamObject.dsRole = dsRole;
};

export const startAccelerateDigitalRetailing = async (data, dealerOptions, dealerConfig: IDealerConfig) => {
    const logger: Logger = Logger.getLogger();
    logger.logEvent({ event: 'accelerate_started' });
    // Frame iFrameQueryParamObjectects
    const sponsor = QueryParams.getParam('dsSponsor') || data.dealer.sponsor;

    const widgetHeight = getWidgetHeight({
        isMobile: data.config && data.config.isMobile,
        vehicleStatus: data.vehicle && data.vehicle.vehicleStatus
    });

    const dealStarterSidebarShowStyle = {
        display: 'block',
        height: widgetHeight,
        width: '100%'
    };

    const dealStarterMainShowStyle = {
        display: 'block',
        width: '100%',
        height: '100%',
        border: '0px',
        'z-index': dealerConfig.sdpModalZIndex || 2147483647
    };

    // initialize the frames
    const iFrameQueryParamObject: IAccelerateExperience = {
        theme: QueryParams.getParam('dsTheme') || dealerConfig.theme || 'default',
        themeRedesign: QueryParams.getParam('dsThemeRedesign') || dealerConfig.themeRedesign || 'global-blue',
        dsEnv: QueryParams.getParam('dsEnv') || 'production',
        dsSource: QueryParams.getParam('dsSource'),
        dsAction: QueryParams.getParam('dsAction'),
        isMinified: QueryParams.getParam('isMinified'),
        condition: data.vehicle.vehicleStatus == 'new' ? 'new' : 'used',
        enableToggles: QueryParams.getParam('enableToggles'),
        disableToggles: QueryParams.getParam('disableToggles'),
        enableDealerToggles: QueryParams.getParam('enableDealerToggles'),
        disableDealerToggles: QueryParams.getParam('disableDealerToggles'),
        dealerId: data.dealer.id,
        ownerId: data.dealer.refId,
        imageUrl: data.vehicle.imageUrl,
        vehicleUrl: data.vehicle.vehicleUrl,
        isMobile: data.config.isMobile ? 'yes' : 'no',
        listedPrice: data.vehicle.listedPrice,
        make: data.vehicle.make,
        mileage: data.vehicle.mileage,
        model: data.vehicle.model,
        sponsor,
        prefill: QueryParams.getParam('dsPrefill') || false,
        styleId: data.vehicle.styleId,
        retailPrice: data.vehicle.retailPrice,
        vin: data.vehicle.vin,
        year: data.vehicle.year,
        trim: data.vehicle.trim,
        type: data.vehicle.vehicleStatus,
        stock: data.vehicle.stock,
        test_name: data.config.test_name,
        listingCode: dealerOptions.listingCode,
        ddcAccountId: data.dealer.ddcAccountId,
        dsConnectionId: QueryParams.getParam('dsConnectionId') || null,
        dsRoute: QueryParams.getParam('dsRoute') || null,
        dsPencilId: QueryParams.getParam('pencilId') || null,
        dsICOTest: QueryParams.getParam('dsICOTest') || null,
        pixallId: data.cookies.pixallId || null,
        vdpTrackingPhoneNumber: data.dealer.vdpTrackingPhoneNumber,
        zipCode: QueryParams.getParam('zip') || data.cookies.zipCode,
        spPr: QueryParams.getParam('spPr'),
        uuid: data.metaData.vehicle.uuid,
        bypassDraasSettingsCache: QueryParams.getParam('bypassDraasSettingsCache'),
        ddcOptimizelyOptIn: optimizelyEdgeOptInDdcList[data.dealer.id] === 1,
        dnaAccountId: (dealerConfig as any).dnaAccountId,
        microSiteEnabled: !!dealerConfig.microSiteEnabled,
        dealXgId: QueryParams.getParam('dealXgId'),
        dealXgVersion: QueryParams.getParam('dealXgVersion'),
        ...data.metadata
    };

    if (data.dealer.isRedesignEnabled && iFrameQueryParamObject.dsSource === 'VIN') {
        addDealerUserParams(iFrameQueryParamObject);
    }

    const externalShopperProfileFields = [
        'annualMiles',
        'creditScoreLow',
        'creditScoreHigh',
        'financeTermMonths',
        'leaseTermMonths',
        'defaultOfferType',
        'downPayment'
    ];

    if (data.shopper) {
        externalShopperProfileFields.forEach((profileField) => {
            const profileValue = data.shopper[profileField];
            if (profileValue) {
                /*
                    Validate profile values.
                    Only 'defaultOfferType' is non numeric, so check if the others are positive.
                    Validate defaultOfferType if it is finance or lease since those are the only valid values.
                */
                if (profileField != 'defaultOfferType' && profileValue > 0) {
                    iFrameQueryParamObject[profileField] = profileValue;
                } else if (profileField === 'defaultOfferType' && (profileValue === 'finance' || profileValue === 'lease')) {
                    iFrameQueryParamObject[profileField] = profileValue;
                }
            }
        });
    }

    // Optimizely test for US299405 - will be short lived 9/13/2019 added
    const mmdWidgetElement = document.getElementById('mmd-widget');
    if (mmdWidgetElement) {
        const experience = mmdWidgetElement.getAttribute('data-experience');
        if (experience) {
            const toggles = (iFrameQueryParamObject.enableToggles || '').split(',');
            if (!toggles.find((value) => value === experience)) {
                toggles.push(experience);
            }
            iFrameQueryParamObject.enableToggles = toggles.join();
        }

        const ctaText = mmdWidgetElement.getAttribute('data-cta-text');
        if (ctaText) {
            iFrameQueryParamObject.ctaText = ctaText;
        }
    }

    if (data.dealer.isRedesignEnabled) {
        iFrameQueryParamObject.dealExperience = 'DR2020';
        logger.addToContext({
            isRedesignEnabled: true
        });
    } else {
        logger.addToContext({
            isRedesignEnabled: false
        });
    }
    const urlDealExperience = QueryParams.getParam('dsDealExperience');
    if (urlDealExperience) {
        iFrameQueryParamObject.dealExperience = urlDealExperience;
    }

    const query = qs.stringify(iFrameQueryParamObject);
    const dealerId = data.dealer.refId ? data.dealer.refId : data.dealer.id;
    const dealStarterSidebarUrl = getPencilUrl(sponsor, dealerId, query);
    const dealStarterMainUrl = getMainUrl(sponsor, dealerId, query);

    const frameConfig = {
        dealerOptions,
        vdpAppUrl: dealStarterSidebarUrl,
        modalAppUrl: dealStarterMainUrl,
        vdpAppShowStyle: dealStarterSidebarShowStyle,
        modalAppShowStyle: dealStarterMainShowStyle,
        onDelayLoaded,
        handleActivEngage
    };

    const frames: any = FramesFactory(frameConfig);
    const vdpApp = frames.VdpApp;
    const masterModal = frames.MasterModal;

    window.dsLaunchRoute = (route) => {
        vdpApp.trigger('LaunchRoute', {
            route
        });
    };

    function onDelayLoaded() {
        masterModal.setSrc(dealStarterMainUrl);
        masterModal.trigger('start', {});

        if (data.metaData) {
            const initialData = {
                meta: data.metaData,
                offer: {}
            };
            masterModal.triggerSync(initialData);
        }
    }

    async function handleActivEngage() {
        return await getActivEngageId(logger, dealerOptions.isActivEngageEnabled);
    }

    masterModal.trigger('start', {});
    vdpApp.trigger('start', {});
    logger.logEvent({ event: 'dealstarter_started' });
    vdpApp.pollWidgetVisibility();

    if (data.metaData) {
        const initialData = {
            meta: data.metaData,
            offer: {}
        };
        masterModal.triggerSync(initialData);
        vdpApp.triggerSync(initialData);
    }

    vdpApp.onResize({ payload: dealerConfig });

    if (iFrameQueryParamObject.dsRoute === 'shopper-platform' && iFrameQueryParamObject.isMobile === 'yes') {
        scrollTo(document.getElementById('mmd-widget'));
    }
    logger.logEvent({ event: 'accelerate_finished' });
    logger.logEvent({ event: 'widget_finished' });
};
