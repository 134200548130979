var _ = require('underscore');

var overrideDealerOptions = module.exports = {

    overrideSponsor : function(sponsor, dealerId) {

        /**
         * Some dealers have been setup with a sponsor that is a proxy for other providers.
         * We need to override the configured sponsor with the correct one to remove need
         * for rewriting data collection logic.
         * e.g. - forddirect is a proxy for ddc and clickmotive (possibly others)
         */

        var overrideSponsor = [
            'forddirect',
            // MWO-1959: Provider implemented the incorrect sponsor.  Overriding here
            // so widget will load.  This should be removed as soon as they update.
            'dealerfire',
            'dealerinspire',
            'dealersocket',
            'ecarlist',
            'eleadone',
            'nakedlime'
        ];

        var dealersByOverrideSponsor = {
            ddc: ['92', '301', '554', '751', '781', '804', '831', '839', '879', '928', '1027', '1082', '1159'],
            clickmotive: ['333', '344', '401', '444', '459', '460', '574', '604', '647', '711', '722', '733', '750', '772', '824', '1204', '1221', '1580'],
            ecarlist: ['878', '948','1659'],
            eleadsdigital: ['1137'],
            dealerfire: ['615', '916', '944'],
            dealerinspire: ['503'],
            dealereprocess: ['1231']
        };

        var data = null;
        var result;

        if(sponsor && overrideSponsor.indexOf(sponsor.toString()) !== -1) {
            data = _.findKey(dealersByOverrideSponsor, function(dlrArr) {
                return _.indexOf(dlrArr, dealerId.toString()) !== -1;
            });
        }

        if(sponsor.toString()==='nakedlime' && dealerId.toString()==='301'){
            data = '';
        }

        if (data) {
            result = data;
        }else{
            result = sponsor;
        }

        return result;
    }

};
