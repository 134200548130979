import * as _ from 'underscore';
import * as Config from '../config';
/**
 * Message router used for communication between host
 * and apps.
 *
 * @param {obj} options Message router settings
 */

interface IWindowExtended extends Window {
    accelerateMessageHook?: any[];
}

export class MessageBus {
    whitelist;
    routes;
    constructor() {
        const legacyWidgetProtocol = Config.services.api.baseWidgetProtocol;
        const dealStarterProtocol = 'https://';
        this.whitelist = [
            legacyWidgetProtocol + 'widget.makemydeal.com',
            legacyWidgetProtocol + 'widget-qa.makemydealpreview.com',
            legacyWidgetProtocol + 'widget-dev.makemydealpreview.com',
            legacyWidgetProtocol + 'localhost:5000',
            legacyWidgetProtocol + 'w2.makemydealpreview.com:5000',
            // DS 2.0 BFF Urls
            dealStarterProtocol + 'dealstarter.makemydeal.com',
            dealStarterProtocol + 'dealstarter-dark.makemydeal.com',
            dealStarterProtocol + 'dealstarter-blue.makemydeal.com',
            dealStarterProtocol + 'dealstarter-green.makemydeal.com',
            dealStarterProtocol + 'dealstarter-staging.makemydeal.com',
            dealStarterProtocol + 'dealstarter-uat.makemydeal.com',
            dealStarterProtocol + 'dealstarter-qa.makemydeal.com',
            dealStarterProtocol + 'dealstarter-dev.makemydeal.com',
            dealStarterProtocol + 'dealstarter-dev2.ca-mmdnp.makemydeal.com',
            dealStarterProtocol + 'dealstarter.makemydeal.dev',
            // Accelerate Urls
            dealStarterProtocol + 'accelerate.dealer.com',
            dealStarterProtocol + 'dark.accelerate.dealer.com',
            dealStarterProtocol + 'uat.accelerate.dealer.com',
            dealStarterProtocol + 'qa.accelerate.dealer.com',
            dealStarterProtocol + 'dev.accelerate.dealer.com',
            dealStarterProtocol + 'dev2.accelerate.dealer.com',
            // Shop Urls
            dealStarterProtocol + 'shop.makemydeal.dev',
            dealStarterProtocol + 'shop.dealer.com',
            dealStarterProtocol + 'dark.shop.dealer.com',
            dealStarterProtocol + 'uat.shop.dealer.com',
            dealStarterProtocol + 'qa.shop.dealer.com',
            dealStarterProtocol + 'dev.shop.dealer.com',
            dealStarterProtocol + 'dev2.shop.dealer.com',

            dealStarterProtocol + 'dr3-assembler-nonprod-us-east-1.service.web-np.dealer.com',
            // Local
            legacyWidgetProtocol + 'dealstarter.makemydeal.dev:5501',
            legacyWidgetProtocol + 'w2.makemydealpreview.com:5501',
            'http://localhost:5501',
            'https://8081.localport.dealer.com',
            'https://8082.localport.dealer.com',
            'https://8083.localport.dealer.com'
        ];

        if (process.env.DEV_BOX) {
            this.whitelist.push(`${legacyWidgetProtocol}widget-dev${process.env.DEV_BOX}.makemydealpreview.com`);
        }

        this.routes = {};
        this.addEventListener();
    }

    addEventListener() {
        const windowExtended: IWindowExtended = window;

        // make sure the array exists (for the first time we hit this code)
        windowExtended.accelerateMessageHook = windowExtended.accelerateMessageHook || [];

        // empty the array 1 by 1 and remove the event listeners
        while (windowExtended.accelerateMessageHook.length > 0) {
            const hook = windowExtended.accelerateMessageHook[0];
            window.removeEventListener('message', hook);
            windowExtended.accelerateMessageHook.splice(0, 1);
        }

        // get the new function to addEventListener
        const bindFn = _.bind(this.broker, this);
        windowExtended.accelerateMessageHook.push(bindFn);

        // add the event listener
        windowExtended.addEventListener('message', bindFn, false);
    }

    checkSource(origin) {
        return this.whitelist.some((element) => {
            return element === '*' || element === origin;
        });
    }

    canIgnoreMessage(event) {
        try {
            var message = JSON.parse(event.data);
            return !message.instanceId;
        } catch (err) {
            return true;
        }
    }

    broker(event) {
        var isAllowed = this.checkSource(event.origin);

        if (!isAllowed) {
            // QUESTION: some sort of logging?
            return;
        }

        if (this.canIgnoreMessage(event)) {
            // This message is from an external source- don't handle it here.
            return;
        }

        var message = JSON.parse(event.data);
        // Fin namespace
        var namespace = message.instanceId + '_';
        var callback = this.routes[namespace + message.type];

        if (!callback) {
            if (window.location.href.toLowerCase().indexOf('enabledebugging') >= 0) {
                throw new Error('No callback found for message event ' + message.type);
            } else {
                return;
            }
        }

        callback(message);
        return;
    }

    bindRoutes(context, routes) {
        // Set route namespace for frame
        var namespace = context.instanceId + '_';

        var addRoute = function (method, route) {
            var methodFunc = context[method];

            if (!methodFunc) {
                throw new Error('There is no event handler ' + method);
            }

            // Bind route handler to the options object used to init frame.
            // TODO: Review.
            this.routes[namespace + route] = _.bind(methodFunc, context);
        };
        _.each(routes, addRoute, this);

        return;
    }
}
