var log = function(msg) {
    global && global.console && global.console.log && global.console.log(msg);
}; // safe function for browser logging

module.exports = function(options) {

    options = options || {};

    //validate mileage
    //remove all non digits if it is a string
    //and parse it
    if (options.mileage && typeof options.mileage == 'string') {
        options.mileage = options.mileage.replace(/\D/g, '');
        options.mileage = parseInt(options.mileage, 10);
    }

    //validate listedPrice
    //remove all non digits if it is a string
    //and parse it
    if (options.listedPrice && typeof options.listedPrice == 'string') {
        options.listedPrice = options.listedPrice.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        options.listedPrice = parseInt(options.listedPrice, 10);
    }

    //validate retailPrice
    //remove all non digits if it is a string
    //and parse it
    if (options.retailPrice && typeof options.retailPrice == 'string') {
        options.retailPrice = options.retailPrice.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        options.retailPrice = parseInt(options.retailPrice, 10);
    }

    //validate dealerId
    //some dealers incorrectly pass an array for dealerid
    //extract first element if it is an array
    if (options.dealerId && options.dealerId.constructor === Array) {
        options.dealerId = options.dealerId[0];
    }

    //validate vehicleStatus
    //some dealers incorrectly pass an array for vehicleStatus
    //extract first element if it is an array
    if (options.vehicleStatus && options.vehicleStatus.constructor === Array) {
        options.vehicleStatus = options.vehicleStatus[0];
    }

    //validate sponsor
    //some dealers pass sponsor with curly braces
    //remove them
    if (options.sponsor && (options.sponsor.indexOf('{') != -1 || options.sponsor.indexOf('}') != -1)) {
        options.sponsor = options.sponsor.replace(/[{}]/g, '');
    }

    //validate stock
    if (options.stock && options.stock.constructor === String && options.stock.indexOf('$') != -1) {
        delete options.stock;
    }

    if (!options.stock) {
        options.stock = " ";
    }

    if (options.trim && options.trim.constructor === String && options.trim.indexOf('$') != -1) {
        delete options.trim;
    }

    //validate vin
    if (options.vin && options.vin.indexOf(' ') != -1) {
        delete options.vin;
        log({
            msg: "Unable to collect vin from dealer site"
        });

    }

    // MWO-127: rules to ensure widget shows as much as possible.
    // Executing this logic after all other validation has occured
    if ((!options.listedPrice || options.listedPrice == ' ') && options.retailPrice) {
        options.listedPrice = options.retailPrice;
    }

    if ((!options.retailPrice || options.retailPrice === ' ')) {
        delete options.retailPrice;
        if (options.vehicleStatus === 1) {
            log({
                msg: "Unable to collect retailPrice from dealer site"
            });
        }
    }

    if ((!options.vehicleStatus || options.vehicleStatus == ' ') && (options.mileage && options.mileage > 500)) {
        options.vehicleStatus = 0;
    }

    if (options.vehicleStatus && options.vehicleStatus == 1 && (!options.mileage || options.mileage == ' ')) {
        options.mileage = 1;
    }

    if (!options.certifiedSatus) {
        options.certifiedSatus = false;
    }

    // MMD-5234 - Normalizing vehicle details url instead of having so many options
    if (options.url) {
        options.vehicleUrl = options.url;
        delete options.url;
    } else {
        options.vehicleUrl = document.URL;
    }

    return options;

};