
// Simple IE9 console.log hack
if (!window.console) window.console = { log: function () { } };

var _ = require('underscore');
var Q = require('q');

/**
 * Represents an iframed application.
 * @param {object}
 * @param {DOM element}
 */

var frameOptions = [
    'name',
    'src',
    'target',
    'insertType',
    'onTargetFail',
    'style',
    'router',
    'routes',
    'onLoad',
    'onError',
    'wrapper',
    'onReconnect'
];

var frameIdPrefix = 'mmd-frame-';

function Appframe(options) {
    options || (options = {});
    this._options = options;
    this.name = options.frameId;
    this.instanceId = options.frameId;
    this.origin = '';
    this.target = '';
    this.insertType = 'append';
    this.onTargetFail = null;
    this.src = '';
    this.style = null;
    this.router = null;
    this.routes = {};
    this.isDeferred = false;
    this.isReady = false;

    _.extend(this, _.pick(options, frameOptions));

    // Add instanceId to src
    var newSrcUrl = this.src;
    newSrcUrl += this.src.indexOf('?') >= 0 ? '&' : '?';
    newSrcUrl += 'instanceId=' + this.instanceId;
    this.src = newSrcUrl;

    this._deferred = null;

    // Resolved when ready is received
    this._readyDeferral = Q.defer();

    this._setEl();
    this._attach();
}

Appframe.prototype._setReady = function () {
    if (this.isReady) {
        return this.onReconnect();
    };

    this.isReady = true;
    this._readyDeferral.resolve('ready');
};

Appframe.prototype.initialize = function () { };

Appframe.prototype.onSuccess = function () { };

Appframe.prototype.onFail = function () { };

Appframe.prototype.onReconnect = function () { };

Appframe.prototype.attach = function (options) {
    if (!this.isDeferred) {
        return console.log(this.name + ' is already attached');
    }

    _.extend(this, _.pick(options, frameOptions));

    this.isDeferred = false;
    this._attach();
};

Appframe.prototype.setStyle = function (cssRules, el) {
    el = el || this.$el;

    if (!el.style) el.style = {};

    for (var prop in cssRules) {
        if (cssRules.hasOwnProperty(prop)) {
            el.style[prop] = cssRules[prop];
        }
    }
};

Appframe.prototype.setOrigin = function (src) {
    var components = src.split('/');
    var protocol = components[0] + '//';
    var host = components[2];

    this.origin = protocol + host;
};

// Currently, if the frame fails to find a target
// after it has been deferred, it will take in an
// object which it uses to construct a dom element
// to wrap the iframe in. You can tell it to attach to
// the scripts parent by using useAnchor attribute.
Appframe.prototype._onTargetFailed = function () {

    // If you can't find target and frame has already been deffered
    // just log error.
    if (!this.onTargetFail && this.isDeferred) {
        console.log('Couldnt find target ' + this.target);
    }

    if (!this.onTargetFail) {
        this.isDeferred = true;
        return false;
    }

    if (typeof this.onTargetFail === 'function') {
        // Must return dom element of false or deferr.
        return this.onTargetFail.call(this);
    }

    if (typeof this.onTargetFail !== 'object') {
        return console.log('Needs to be a function or an object.');
    }

    var failConfig = this.onTargetFail
    var $container = document.createElement(failConfig.tag || 'div');

    if (failConfig.id) {
        $container.setAttribute("id", failConfig.id);
    }

    if (!failConfig.useAnchor) {
        return console.log('Need to define a target element.');
    }

    failConfig.useAnchor.parentNode.insertBefore($container, failConfig.useAnchor);
    return $container;
};

Appframe.prototype.setTarget = function () {
    if (this.target instanceof HTMLElement) {
        this.$target = this.target;
    } else {
        this.$target = (this.target === 'body') ? document.body : document.querySelector(this.target);
    }

    // If there is no target DOM found, run through
    // the on failed action.
    if (!this.$target) {
        this.$target = this._onTargetFailed();
    };
};

Appframe.prototype.trigger = function (type, payload) {
    var _this = this;

    var message = {
        type: type,
        payload: payload
    };

    // Wait until iframe is loaded before trying postMessage
    this._readyDeferral.promise.then(function () {
        _this.$el = document.getElementById(frameIdPrefix + _this.instanceId);
        _this.receiver = _this.$el.contentWindow;

        _this.receiver.postMessage(JSON.stringify(message), _this.origin);
    });
};

Appframe.prototype.triggerSync = function (payload, target) {
    target = target || '*';
    var _this = this;

    var message = {
        message: JSON.stringify(payload),
        sourceId: 'MASTER',
        targetId: target,
        context: 'SYNC'
    };

    // Wait until iframe is loaded before trying postMessage
    this._readyDeferral.promise.then(function () {
        _this.$el = document.getElementById(frameIdPrefix + _this.instanceId);
        _this.receiver = _this.$el.contentWindow;

        _this.receiver.postMessage(JSON.stringify(message), _this.origin);
    });
};

Appframe.prototype._setEl = function () {
    this.$el = document.createElement("iframe");
    this.$el.id = frameIdPrefix + this.instanceId;
    this.$el.src = this.src;
    this.$el.onload = this.onLoad || null;
    this.$el.onerror = this.onError || null;
    this.setStyle(this.style, this.$el);

    this.setOrigin(this.src);

    if (this.wrapper) {
        this.$wrapper = document.createElement("div");
        this.$wrapper.id = this.wrapper.id;
        this.setStyle(this.wrapper.style, this.$wrapper);
        this.$wrapper.appendChild(this.$el);
    };
};

Appframe.prototype._bindRoutes = function () {
    if (!this.routes) {
        return;
    }

    if (this.routes && !this.router) {
        return console.log('A router instance needs to be passed in.');
    }

    this.routes.ready = '_setReady';

    var handlers = _.values(this.routes);

    var mergeToRoot = function (name) {
        if (!this._options[name]) {
            return;
        }

        if (this[name]) {
            return console.log('A route handler is attempting to override an existing method.');
        }

        this[name] = this._options[name];
    };

    _.each(handlers, mergeToRoot, this);
    this.router.bindRoutes(this, this.routes);
};

Appframe.prototype._attach = function () {
    this.setTarget();

    if (this.isDeferred) {
        return;
    };

    if (!this.$target) {
        return console.log('You need to specify a container for the frame. ' + this.name);
    }

    // Initialize hook
    this.initialize();
    this.insertType === 'append' ? this.$target.appendChild(this.$wrapper || this.$el)
        : this.$target.insertBefore(this.$wrapper || this.$el, this.$target.childNodes[0]);

    this.receiver = this.$el.contentWindow;
    this._bindRoutes();
};

Appframe.prototype.isMissing = function () {
    return !document.getElementById(frameIdPrefix + this.instanceId);
};

Appframe.prototype.isParentHidden = function (el, lim, isHiddenCallback) {
    lim = lim || 0;
    el = el || document.querySelector('#mmd-widget');

    function hidden(el) {
        return window.getComputedStyle(el).display === 'none' || window.getComputedStyle(el).visibility === 'hidden';
    }

    //control 
    if (lim === 5) {
        return;
    }

    if (el !== null && el.nodeName === 'BODY') {
        return;
    }

    //get parent 
    el = el.parentNode || null;

    //search up dom tree for hidden parent 
    if (el) {
        if (hidden(el)) {
            isHiddenCallback(el, lim);
            return;
        } else {
            this.isParentHidden(el, lim + 1, isHiddenCallback);
        }
    }
};

module.exports = Appframe;
