var _ = require('underscore');

var overrideDealerOptions = require('./overrideDealerOptions');
var getDataAttributes = require('../getDataAttributes');

var libUtils = module.exports = {
    overrideSponsor: function(sponsor, dealerId) {
        var result = overrideDealerOptions.overrideSponsor(sponsor, dealerId);
        return result;
    },

    getQueryParameterByName: function(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        var results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    initDlrOptsFromRoot: function(mmd) {
        //TODO: understand what is mmd.version. it seems to be initialized inside current 
        return !!(mmd && mmd.version && mmd.sponsor && (mmd.refId || mmd.dealerId));
    },

    getScriptConfig: function() {
        var $script = document.getElementById('mmd-widget-script') || document.getElementById('makemydeal') || null;
        var scriptConfig = ($script) ? (getDataAttributes($script)) : {};
        scriptConfig = _.mapObject(scriptConfig, function(value, index, list) {
            return _.isString(value) ? value.toLowerCase().trim() : value;
        });

        // HACK - DDC passed chromeId instead of chromeStyleId; mapping to chromeStyleId if it exists
        if (scriptConfig && scriptConfig.chromeId) {
            scriptConfig.chromeStyleId = scriptConfig.chromeId;
            delete scriptConfig.chromeId;
        }

        return scriptConfig;
    },

    scrollTo: function(element) {
        window.scroll({
            behavior: 'smooth',
            left: 0,
            top: element.offsetTop
        });
    },

    onTriggerExternalTrack: function (frameConfig) {
            var frameCfg = frameConfig;
            return function(eventMsg) {
                var onTrackHook = frameConfig.dealerOptions['onTrack'];
                let payload;
                if (typeof(eventMsg.payload) === 'string') {
                    payload = JSON.parse(eventMsg.payload)
                    if (!payload.eventName) {
                        return;
                    }
                } else {
                    payload = eventMsg.payload;
                }
            
                if (onTrackHook) {
                    onTrackHook(payload.eventName, payload.eventAction, payload.eventData);
                }
            }
        }
};
