'use strict';
var QueryParams = require('./widget_modules/queryParams');
var config = module.exports = {};
var env_configs = {};
env_configs.services = {};
env_configs.logstash_env = 'non-prod';
var dealStarterQSEnv = QueryParams.getParam('dsEnv');
var CONFIG_ENV = dealStarterQSEnv || process.env.NODE_ENV;

function isLegacyIE() {
    return navigator.userAgent.indexOf('MSIE 10') > -1 ||
            navigator.userAgent.indexOf('MSIE 9') > -1 ||
            navigator.userAgent.indexOf('MSIE 8') > -1 ||
            navigator.userAgent.indexOf('MSIE 7') > -1;
}

switch (CONFIG_ENV) {
    case 'production':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget.makemydeal.com'
        };

        env_configs.logstash_env = 'prod';
        break;

    case 'dark-prod':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget.makemydeal.com'
        };

        env_configs.logstash_env = 'prod';
        break;

    case 'uat':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget.makemydeal.com'
        };
        break;

    case 'staging':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-staging.makemydeal.com'
        };
        break;

    case 'staging2':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-staging2.makemydeal.com'
        };
        break;

    case 'qa':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-qa.makemydealpreview.com'
        };
        break;

    case 'qa2':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-qa2.makemydealpreview.com'
        };
        break;

    case 'dev':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget-dev.makemydealpreview.com'
        };
        break;

    case 'local-production':
        env_configs.services.api = {
            protocol: 'https://',
            host: 'widget.makemydeal.com'
        };
        break;

    case 'local':
        env_configs.services.api = {
            protocol: 'http://',
            host: 'w2.makemydealpreview.com:5000'
        };
        break;

    case 'local-ssl':
        env_configs.services.api = {
            protocol: 'http://',
            host: 'w2.makemydealpreview.com:5000'
        };
        break;

    default: // defaults to localhost
        env_configs.services.api = {
            protocol: 'http://',
            host: 'localhost:5000'
        };

}

config.logstash_env = env_configs.logstash_env;

config.services = {
    api: {
        // IE10, IE9 needs to use the current protocol for CORS requests
        // IE7 will be returned in compatibility mode
        baseApiUrl: (isLegacyIE() ?
            document.location.protocol + '//' :
            env_configs.services.api.protocol) + env_configs.services.api.host,
        // Load widgetUrl using configured protocol
        baseWidgetUrl: env_configs.services.api.protocol + env_configs.services.api.host,
        baseWidgetProtocol: env_configs.services.api.protocol
    },
    dsApi: {
        endpoints: {
            verify: 'ds/verify',
            experience: 'ds/experience'
        }
    }
};

config.getdsApiUrl = function(sponsor) {
    var domain = 'shop.dealer.com';

    if(sponsor.toLowerCase() === 'kbb' || sponsor.toLowerCase() === 'atc') {
        domain = 'accelerate.dealer.com';
    }

    switch (CONFIG_ENV) {
        case 'production':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: domain
            };
            break;

        case 'dark-prod':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'dark.' + domain
            };
            break;

        case 'staging':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'staging.' + domain
            };
            break;

        case 'uat':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'uat.' + domain
            };
            break;

        case 'qa':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'qa.' + domain
            };
            break;

        case 'dev':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'dev.' + domain
            };

            break;

        case 'local-production':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: domain
            };

            break;

        case 'local':
            env_configs.services.dsApi = {
                protocol: 'http://',
                host: 'dealstarter.makemydeal.dev:5501'
            };

            break;

        case 'local-ssl':
            env_configs.services.dsApi = {
                protocol: 'https://',
                host: 'dealstarter.makemydeal.dev'
            };

            break;

        default:
            env_configs.services.dsApi = {
                protocol: 'http://',
                host: 'w2.makemydealpreview.com:5501'
            };
    }

    return (isLegacyIE() ?
        document.location.protocol + '//' :
        env_configs.services.dsApi.protocol) + env_configs.services.dsApi.host;
};
